import { useEffect, useState } from 'react'
import Loader from './Loader'

const TermlyEmbed = ({ id }: { id: string }) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const scriptId = 'termly-jssdk'

    // Check if the script is already added
    if (document.getElementById(scriptId)) {
      setIsLoading(false)
      return
    }

    const script = document.createElement('script')
    script.id = scriptId
    script.src = 'https://app.termly.io/embed-policy.min.js'
    script.type = 'text/javascript'
    script.async = true

    script.onload = () => {
      setIsLoading(false) // Hide loader after script is loaded
    }

    script.onerror = () => {
      setIsLoading(false) // Handle error case by hiding loader
      console.error('Failed to load Termly script.')
    }

    document.body.appendChild(script)

    return () => {
      // Cleanup script if needed
      const existingScript = document.getElementById(scriptId)
      if (existingScript) {
        existingScript.remove()
      }
    }
  }, [])

  return (
    <>
      {isLoading && (
        <div className='h-screen flex items-center justify-center'>
          <Loader />
        </div>
      )}
      <div
        dangerouslySetInnerHTML={{
          __html: `<div name='termly-embed' data-termly-embed='true' data-id=${id}></div>`
        }}
      />
    </>
  )
}

export default TermlyEmbed
