import { Close } from '@mui/icons-material'
import { Tooltip, Typography } from '@mui/material'
import { Modal } from 'antd'
import { LoginCurve } from 'iconsax-react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { useAuth } from '../../../contexts/authContext'
import { persistor } from '../../../redux/store'
import { clearUserData } from '../../../redux/userDefaultData/userDefaultData'
import { getColorCode } from '../../../utils/helper'

const LogOutBtn = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()
  const { logout } = useAuth()
  const dispatch = useDispatch()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handlelogout = () => {
    logout()
    dispatch(clearUserData()) // Clears Redux state
    persistor.purge() // Clears persisted data
    navigate('/login')
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: '#707e8c'
            }
          }
        }}
        title={<Typography>Logout</Typography>}
      >
        <div
          className={`log_out_btn${
            process.env.REACT_APP_COLOR === 'hitron'
              ? '_hitron'
              : process.env.REACT_APP_COLOR === 'customer'
              ? '_customer'
              : ''
          }`}
          onClick={showModal}
          style={{
            color: getColorCode('redToBlue'),
            border: `2px solid ${getColorCode('redToBlue')}`,
            cursor: 'pointer'
          }}
          data-testid='testid_logout_button'
        >
          <LoginCurve size={28} />
        </div>
      </Tooltip>
      <Modal
        open={isModalOpen}
        footer={null}
        closable={false}
        width={600}
        wrapClassName={'log_out_wrapper'}
        centered={true}
      >
        <div className=''>
          <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
            <h3
              style={{ fontSize: '20px', fontWeight: '600', width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              Logout
            </h3>
            <Close
              className='cursor-pointer'
              onClick={() => {
                handleCancel()
              }}
            />
          </div>
          <div className='log_out_body_wrapper'>
            <div className='log_out_body'>
              <h3 className='conformation_text'>
                {/* Have you successfully resolved the customer’s issue and addressed the pending ticket? */}
                Are you sure you want to logout of the system?
              </h3>
              {/* <p className='commit_text'>Afterward, please proceed to log out.</p> */}
            </div>
            <div className='log_out_footer'>
              {/* <Button
                type='button'
                onClick={handleCancel}
                // className='cancel_btn'
                sx={{
                  marginRight: '10px',
                  color: getColorCode('cancelBtnText'),
                  background: getColorCode('cancelBtnBg'),
                  border: `2px solid ${getColorCode('cancelBtnText')}`
                }}
              >
                Cancel
              </Button> */}
              <button
                type='button'
                className='logout_btn'
                onClick={handlelogout}
                style={{
                  textTransform: 'uppercase',
                  color: getColorCode('logOutBtnText'),
                  background: getColorCode('logOutBtnBg'),
                  border: `2px solid ${getColorCode('logOutBtnText')}`,
                  fontWeight: 'bold'
                }}
                data-testid='testid_logout_button2'
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default LogOutBtn
