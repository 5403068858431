export const isIphone = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const genericIphoneStrings = [
    'iphone',
    'iphone 12',
    'iphone 12 pro',
    'iphone 12 pro max',
    'iphone 12 mini',
    'iphone 11',
    'iphone 11 pro',
    'apple iphone',
    'apple iphone 12',
    'apple iphone 12 pro',
    'apple iphone 12 pro max',
    'apple iphone 12 mini',
    'apple iphone 11',
    'apple iphone 11 pro',
    'apple iphone 12',
    'apple iphone 12 pro',
    'apple iphone 12 pro max',
    'apple iphone 12 mini',
    'apple iphone 11',
    'apple iphone 11 pro',
    'iphone se',
    'apple iphone se',
    'apple iphone se 2020',
    'apple iphone se2',
    'apple iphone se 2',
    'apple iphone se2020',
    'apple iphone se-2020',
    'apple iphone se-2',
    'apple iphone se2',
    'iphone x',
    'apple iphone x',
    'apple iphone x',
    'apple iphone x',
    'apple iphone x',
    'apple iphone x',
    'apple iphone x'
  ]

  const isAppleOs = lowercasedStrings.includes('apple os') || lowercasedStrings.includes('apple, inc.')

  const matchedString = genericIphoneStrings.some(string => lowercasedStrings.includes(string))

  if (isAppleOs || matchedString) {
    return true
  } else {
    return false
  }
}

export const isIpad = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const genericIpadStrings = [
    'ipad',
    'ipad pro',
    'ipad air',
    'ipad mini',
    'ipad 8',
    'ipad 7',
    'ipad 6',
    'ipad 5',
    'ipad 4',
    'ipad 3',
    'ipad 2',
    'ipad 1',
    'apple ipad',
    'apple ipad pro',
    'apple ipad air',
    'apple ipad mini',
    'apple ipad 8',
    'apple ipad 7',
    'apple ipad 6',
    'apple ipad 5',
    'apple ipad 4',
    'apple ipad 3',
    'apple ipad 2',
    'apple ipad 1'
  ]

  const isAppleOs = lowercasedStrings.includes('apple os') || lowercasedStrings.includes('apple, inc.')

  const matchedString = genericIpadStrings.some(string => lowercasedStrings.includes(string))

  if (isAppleOs || matchedString) {
    return true
  } else {
    return false
  }
}

export const isMacBook = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const genericMacOs = [
    'mac os x',
    'macosx',
    'mac-osx',
    'mac osx',
    'macos x',
    'mac-os x',
    'mac os x',
    'macosx',
    'mac-osx',
    'mac osx',
    'macos x',
    'mac-os x',
    'mac os',
    'macos',
    'mac-os',
    'macos',
    'mac os',
    'macos',
    'mac-os',
    'macos',
    'apple os',
    'appleos',
    'apple-os',
    'appleos',
    'apple os',
    'appleos',
    'apple-os',
    'appleos',
    'macbook pro',
    'apple macbook pro',
    'macbook air',
    'apple macbook air',
    'macbook',
    'apple macbook',
    'macbook pro 13',
    'apple macbook pro 13',
    'macbook air 13',
    'apple macbook air 13',
    'macbook 13',
    'apple macbook 13',
    'macbook pro 15',
    'apple macbook pro 15',
    'macbook air 15',
    'apple macbook air 15',
    'macbook 15',
    'apple macbook 15',
    'macbook pro 16',
    'apple macbook pro 16',
    'macbook air 16',
    'apple macbook air 16',
    'macbook 16',
    'apple macbook 16'
  ]
  const isOperatingSystem = lowercasedStrings.includes('operating system')
  const isMacOs = lowercasedStrings.includes('apple os')

  const matchedString = genericMacOs.some(string => lowercasedStrings.includes(string))

  return matchedString
}

export const isAndroidTV = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const genericAndroidTVStrings = [
    'android tv',
    'androidtv',
    'android-tv',
    'android tv box',
    'androidtv box',
    'android-tv box',
    'android tvbox',
    'androidtvbox',
    'android-tvbox',
    'android tv stick',
    'androidtv stick',
    'android-tv stick',
    'android tvstick',
    'androidtvstick',
    'android-tvstick',
    'android tv dongle',
    'androidtv dongle',
    'android-tv dongle',
    'android tvdongle',
    'androidtvdongle',
    'android-tvdongle',
    'android tv device',
    'androidtv device',
    'android-tv device',
    'android tvdevice',
    'androidtvdevice',
    'android-tvdevice',
    'android tv set',
    'androidtv set',
    'android-tv set',
    'android tvset',
    'androidtvset',
    'android-tvset',
    'android tv box',
    'androidtv box',
    'android-tv box',
    'android tvbox',
    'androidtvbox',
    'android-tvbox',
    'fire tv',
    'firetv',
    'fire-tv',
    'mi box',
    'mibox',
    'mi-box',
    'nvidia shield',
    'nvidiashield',
    'nvidia-shield',
    'shield tv',
    'shieldtv',
    'shield-tv',
    'google tv',
    'googletv',
    'google-tv',
    'chromecast',
    'chromecast with google tv',
    'chromecastwith google tv',
    'chromecast-with google tv',
    'chromecastwithgoogle tv',
    'chromecastwithgoogle-tv',
    'chromecastwithgoogle-tv',
    'chromecast withgoogle tv',
    'chromecast withgoogletv',
    'chromecast withgoogle-tv',
    'philips tv',
    'philipstv',
    'philips-tv',
    'philips android tv',
    'philipsandroid tv',
    'philips-android tv',
    'philips androidtv',
    'philipsandroidtv',
    'philips-androidtv',
    'sony tv',
    'sonytv',
    'sony-tv',
    'sony android tv',
    'sonyandroid tv',
    'sony-android tv',
    'sony androidtv',
    'sonyandroidtv',
    'sony-androidtv',
    'tcl tv',
    'tcltv',
    'tcl-tv',
    'tcl android tv',
    'tclandroid tv',
    'tcl-android tv',
    'tcl androidtv',
    'tclandroidtv',
    'tcl-androidtv',
    'sharp tv',
    'sharptv',
    'sharp-tv',
    'sharp android tv',
    'sharpandroid tv',
    'sharp-android tv',
    'sharp androidtv',
    'sharpandroidtv',
    'sharp-androidtv',
    'hisense tv',
    'hisensetv',
    'hisense-tv',
    'hisense android tv',
    'hisenseandroid tv',
    'hisense-android tv',
    'hisense androidtv',
    'hisenseandroidtv',
    'hisense-androidtv',
    'skyworth tv',
    'skyworthtv',
    'skyworth-tv',
    'skyworth android tv',
    'skyworthandroid tv',
    'skyworth-android tv',
    'skyworth androidtv',
    'skyworthandroidtv',
    'skyworth-androidtv',
    'vizio tv',
    'viziotv',
    'vizio-tv',
    'vizio android tv',
    'vizioandroid tv',
    'vizio-android tv',
    'vizio androidtv',
    'vizioandroidtv',
    'vizio-androidtv',
    'sony-android tv',
    'lg tv',
    'lgtv',
    'lg-tv',
    'lg android tv',
    'lgandroid tv',
    'lg-android tv',
    'lg androidtv',
    'lgandroidtv',
    'lg-androidtv',
    'samsung tv',
    'samsungtv',
    'samsung-tv',
    'samsung android tv',
    'samsungandroid tv',
    'samsung-android tv',
    'samsung androidtv',
    'samsungandroidtv',
    'samsung-androidtv',
    'panasonic tv',
    'panasonictv',
    'panasonic-tv',
    'panasonic android tv',
    'panasonicandroid tv',
    'panasonic-android tv',
    'panasonic androidtv',
    'panasonicandroidtv',
    'panasonic-androidtv',
    'toshiba tv',
    'toshibatv',
    'toshiba-tv',
    'toshiba android tv',
    'toshibaandroid tv',
    'toshiba-android tv',
    'toshiba androidtv',
    'toshibaandroidtv',
    'toshiba-androidtv',
    'philips tv',
    'philipstv',
    'philips-tv',
    'philips android tv',
    'philipsandroid tv',
    'philips-android tv',
    'philips androidtv',
    'philipsandroidtv',
    'philips-androidtv',
    'jvc tv',
    'jvctv',
    'jvc-tv',
    'jvc android tv',
    'jvcandroid tv',
    'jvc-android tv',
    'jvc androidtv',
    'jvcandroidtv',
    'jvc-androidtv',
    'mitv',
    'mi tv',
    'mi-tv',
    'mi android tv',
    'miandroid tv',
    'mi-android tv',
    'mi androidtv',
    'miandroidtv',
    'mi-androidtv',
    'xiaomi tv',
    'xiaomitv',
    'xiaomi-tv',
    'xiaomi android tv',
    'xiaomiandroid tv',
    'xiaomi-android tv',
    'xiaomi androidtv',
    'xiaomiandroidtv',
    'xiaomi-androidtv',
    'oneplus tv',
    'oneplustv',
    'oneplus-tv',
    'oneplus android tv',
    'oneplusandroid tv',
    'oneplus-android tv',
    'oneplus androidtv',
    'oneplusandroidtv',
    'oneplus-androidtv',
    'realme tv',
    'realmetv',
    'realme-tv',
    'realme android tv',
    'realmeandroid tv',
    'realme-android tv',
    'realme androidtv',
    'realmeandroidtv',
    'realme-androidtv',
    'xiaomi mi box',
    'xiaomimibox',
    'xiaomi-mi box',
    'xiaomi mi-box',
    'xiaomimibox',
    'xiaomi-mibox',
    'xiaomi mi box s',
    'xiaomimibox s',
    'xiaomi-mi box s',
    'xiaomi mi-box s',
    'xiaomimibox s',
    'xiaomi-mibox s',
    'Youin TV Box',
    'YouinTV Box',
    'Youin-TV Box',
    'Youin TVBox',
    'YouinTVBox',
    'Youin-TVBox',
    'Xiaomi Mi TV Stick',
    'XiaomiMi TV Stick',
    'Xiaomi-Mi TV Stick',
    'Xiaomi MiTV Stick',
    'XiaomiMiTV Stick',
    'Xiaomi-MiTV Stick',
    'Xiaomi Mi TV Stick 4K',
    'XiaomiMi TV Stick 4K',
    'Xiaomi-Mi TV Stick 4K',
    'Xiaomi MiTV Stick 4K',
    'XiaomiMiTV Stick 4K',
    'Xiaomi-MiTV Stick 4K',
    'Xiaomi Mi TV Stick Lite',
    'XiaomiMi TV Stick Lite',
    'Xiaomi-Mi TV Stick Lite',
    'Xiaomi MiTV Stick Lite',
    'XiaomiMiTV Stick Lite',
    'Xiaomi-MiTV Stick Lite',
    'Xiaomi Mi TV Box S',
    'XiaomiMi TV Box S',
    'Xiaomi-Mi TV Box S',
    'Xiaomi MiTV Box S',
    'XiaomiMiTV Box S',
    'Xiaomi-MiTV Box S',
    'Xiaomi Mi TV Box 4K',
    'XiaomiMi TV Box 4K',
    'Xiaomi-Mi TV Box 4K',
    'Xiaomi MiTV Box 4K',
    'XiaomiMiTV Box 4K',
    'Xiaomi-MiTV Box 4K',
    'Xiaomi Mi TV Box 3',
    'XiaomiMi TV Box 3',
    'Xiaomi-Mi TV Box 3',
    'Xiaomi MiTV Box 3',
    'XiaomiMiTV Box 3',
    'Xiaomi-MiTV Box 3'
  ]

  const lowercasedGenericAndroidTVStrings = genericAndroidTVStrings.map(string => string.toLowerCase())

  const matchedString = lowercasedGenericAndroidTVStrings.some(string => lowercasedStrings.includes(string))

  return matchedString
}

const isTv = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const genericTvStrings = [
    'tv',
    'MI TV',
    'MITV',
    'MI-TV',
    'MI TV',
    'MITV',
    'MI-TV',
    'mitv',
    'panasonic tv',
    'panasonictv',
    'panasonic-tv',
    'panasonic android tv',
    'panasonicandroid tv',
    'panasonic-android tv',
    'panasonic androidtv',
    'panasonicandroidtv',
    'panasonic-androidtv',
    'lg tv',
    'lgtv',
    'lg-tv',
    'lg android tv',
    'lgandroid tv',
    'lg-android tv',
    'lg androidtv',
    'lgandroidtv',
    'lg-androidtv',
    'samsumg tv',
    'samsumgtv',
    'samsumg-tv',
    'samsumg android tv',
    'samsumgandroid tv',
    'samsumg-android tv',
    'samsumg androidtv',
    'samsumgandroidtv',
    'samsumg-androidtv',
    'sony tv',
    'sonytv',
    'sony-tv',
    'sony android tv',
    'sonyandroid tv',
    'sony-android tv',
    'sony androidtv',
    'sonyandroidtv',
    'sony-androidtv',
    'tcl tv',
    'tcltv',
    'tcl-tv',
    'tcl android tv',
    'tclandroid tv',
    'tcl-android tv',
    'tcl androidtv',
    'tclandroidtv',
    'tcl-androidtv',
    'sharp tv',
    'sharptv',
    'sharp-tv',
    'sharp android tv',
    'sharpandroid tv',
    'sharp-android tv',
    'sharp androidtv',
    'sharpandroidtv',
    'sharp-androidtv',
    'Nixplay Seed WiFi Digital Picture Frame',
    'DIXONS-JVC-TV',
    'Youin TV Box',
    'YouinTV Box',
    'Youin-TV Box',
    'Youin TVBox',
    'YouinTVBox',
    'Youin-TVBox',
    'samsung',
    'Google OS'
  ]

  const lowercasedGenericAndroidTVStrings = genericTvStrings.map(string => string.toLowerCase())

  const matchedString = lowercasedGenericAndroidTVStrings.some(string => lowercasedStrings.includes(string))

  return matchedString
}

const isRingCamera = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const genericRingCameraStrings = [
    'ring camera',
    'ringcamera',
    'ring-camera',
    'ring cameras',
    'ringcameras',
    'ring-cameras',
    'ring doorbell',
    'ringdoorbell',
    'ring-doorbell',
    'ring doorbells',
    'ringdoorbells',
    'ring-doorbells',
    'ring floodlight',
    'ringfloodlight',
    'ring-floodlight',
    'ring floodlights',
    'ringfloodlights',
    'ring-floodlights',
    'ring spotlight',
    'ringspotlight',
    'ring-spotlight',
    'ring spotlights',
    'ringspotlights',
    'ring-spotlights',
    'ring stick up cam',
    'ringstick up cam',
    'ring-stick up cam',
    'ring stick up cams',
    'ringstick up cams',
    'ring-stick up cams',
    'ring security camera'
  ]

  const matchedString = genericRingCameraStrings.some(string => lowercasedStrings.includes(string))

  return matchedString
}

export const isAppleTv = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const genericAppleTVStrings = [
    'apple tv',
    'appletv',
    'apple-tv',
    'apple tv box',
    'appletv box',
    'apple-tv box',
    'apple tvbox',
    'appletvbox',
    'apple-tvbox',
    'apple tv stick',
    'appletv stick',
    'apple-tv stick',
    'apple tvstick',
    'appletvstick',
    'apple-tvstick',
    'apple tv dongle',
    'appletv dongle',
    'apple-tv dongle',
    'apple tvdongle',
    'appletvdongle',
    'apple-tvdongle',
    'apple tv device',
    'appletv device',
    'apple-tv device',
    'apple tvdevice',
    'appletvdevice',
    'apple-tvdevice',
    'apple tv set',
    'appletv set',
    'apple-tv set',
    'apple tvset',
    'appletvset',
    'apple-tvset',
    'apple tv box',
    'appletv box',
    'apple-tv box',
    'apple tvbox',
    'appletvbox',
    'apple-tvbox',
    'apple tv 4k',
    'appletv 4k',
    'apple-tv 4k',
    'apple tv4k',
    'appletv4k',
    'apple-tv4k',
    'apple tv 4k 2021',
    'appletv 4k 2021',
    'apple-tv 4k 2021',
    'apple tv4k 2021',
    'appletv4k 2021',
    'apple-tv4k 2021'
  ]

  const matchedString = genericAppleTVStrings.some(string => lowercasedStrings.includes(string))

  return matchedString
}

export const isXunisonDevice = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const genericXunisonDevices = ['xunison', 'xunison smart home', 'xunison ltd.']

  const matchedString = genericXunisonDevices.some(string => lowercasedStrings.includes(string))

  return matchedString
}

export const isPrinter = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const genericPrinterStrings = [
    'printer',
    'printers',
    'print',
    'printing',
    'printout',
    'print out',
    'print-out',
    'print-outs',
    'print outs',
    'print-outs',
    'inkjet',
    'ink jet',
    'ink-jet',
    'inkjets',
    'ink jets',
    'ink-jets',
    'inkjet printer',
    'ink jet printer',
    'ink-jet printer',
    'inkjet printers',
    'ink jet printers',
    'ink-jet printers',
    'laser',
    'laser printer',
    'scanner',
    'scanners',
    'scan',
    'scanning',
    'scanned',
    'scan out',
    'scan-out',
    'scan-out',
    'scan outs',
    'scan-outs',
    'scan-outs',
    'hp printer',
    'hp printers',
    'hp-printer',
    'hp-printers',
    'hp-printer',
    'hp printer',
    'hp-printers',
    'hp-printers',
    'canon printer',
    'canon printers',
    'canon-printer',
    'canon-printers',
    'canon-printer',
    'canon printer',
    'canon-printers',
    'canon-printers',
    'epson printer',
    'epson printers',
    'epson-printer',
    'epson-printers',
    'epson-printer',
    'epson printer',
    'epson-printers',
    'epson-printers',
    'brother printer',
    'brother printers',
    'brother-printer',
    'brother-printers',
    'brother-printer',
    'brother printer',
    'brother-printers',
    'brother-printers',
    'samsung printer',
    'samsung printers',
    'samsung-printer',
    'samsung-printers',
    'samsung-printer',
    'samsung printer',
    'samsung-printers',
    'samsung-printers',
    'lexmark printer',
    'lexmark printers',
    'lexmark-printer',
    'lexmark-printers',
    'lexmark-printer',
    'lexmark printer',
    'lexmark-printers',
    'lexmark-printers',
    'xerox printer',
    'xerox printers',
    'xerox-printer',
    'xerox-printers',
    'xerox-printer',
    'xerox printer',
    'xerox-printers',
    'xerox-printers',
    'dell printer',
    'dell printers',
    'dell-printer',
    'dell-printers',
    'dell-printer',
    'dell printer',
    'dell-printers',
    'dell-printers',
    'ricoh printer',
    'ricoh printers',
    'ricoh-printer',
    'ricoh-printers',
    'ricoh-printer',
    'ricoh printer',
    'ricoh-printers',
    'ricoh-printers',
    'kyocera printer',
    'kyocera printers',
    'kyocera-printer',
    'kyocera-printers',
    'kyocera-printer',
    'kyocera printer',
    'kyocera-printers',
    'kyocera-printers',
    'oki printer',
    'oki printers',
    'oki-printer',
    'oki-printers',
    'oki-printer',
    'oki printer',
    'oki-printers',
    'oki-printers',
    'panasonic printer',
    'panasonic printers',
    'panasonic-printer',
    'panasonic-printers',
    'panasonic-printer',
    'panasonic printer',
    'panasonic-printers',
    'panasonic-printers',
    'konica minolta printer',
    'konica minolta printers',
    'konica-minolta-printer',
    'konica-minolta-printers',
    'konica-minolta-printer',
    'konica minolta printer',
    'konica-minolta-printers',
    'konica-minolta-printers',
    'konica-minolta printer',
    'konica-minolta printers',
    'konica-minolta-printer',
    'konica-minolta-printers',
    'konica-minolta-printer',
    'konica-minolta printer',
    'konica-minolta-printers',
    'konica-minolta-printers'
  ]
  const matchedString = genericPrinterStrings.some(string => lowercasedStrings.includes(string))

  return matchedString
}

export const isPlayStation = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const genericPlayStationStrings = [
    'playstation',
    'play station',
    'play-station',
    'playstations',
    'play stations',
    'play-stations',
    'sony playstation',
    'sony play station',
    'sony play-station',
    'sony playstations',
    'sony play stations',
    'sony play-stations',
    'sony playstation 5',
    'sony play station 5',
    'sony play-station 5',
    'sony playstations 5',
    'sony play stations 5',
    'sony play-stations 5',
    'sony playstation 4',
    'sony play station 4',
    'sony play-station 4',
    'sony playstations 4',
    'sony play stations 4',
    'sony play-stations 4',
    'sony playstation 3',
    'sony play station 3',
    'sony play-station 3',
    'sony playstations 3',
    'sony play stations 3',
    'sony play-stations 3',
    'sony playstation 2',
    'sony play station 2',
    'sony play-station 2',
    'sony playstations 2',
    'sony play stations 2',
    'sony play-stations 2',
    'sony playstation 1',
    'sony play station 1',
    'sony play-station 1',
    'sony playstations 1',
    'sony play stations 1',
    'sony play-stations 1',
    'sony playstation 5 pro',
    'sony play station 5 pro',
    'sony play-station 5 pro',
    'sony playstations 5 pro',
    'sony play stations 5 pro',
    'sony play-stations 5 pro',
    'sony playstation 4 pro',
    'sony play station 4 pro',
    'sony play-station 4 pro',
    'sony playstations 4 pro',
    'sony play stations 4 pro',
    'sony play-stations 4 pro',
    'sony playstation 3 pro',
    'sony play station 3 pro',
    'sony play-station 3 pro',
    'sony playstations 3 pro',
    'sony play stations 3 pro',
    'sony play-stations 3 pro',
    'sony playstation 2 pro',
    'sony play station 2 pro',
    'sony play-station 2 pro',
    'sony playstations 2 pro',
    'sony play stations 2 pro',
    'sony play-stations 2 pro',
    'sony playstation 1 pro',
    'sony play station 1 pro',
    'sony play-station 1 pro',
    'sony playstations 1 pro',
    'sony play stations 1 pro',
    'sony play-stations 1 pro',
    'sony playstation 5 slim',
    'sony play station 5 slim',
    'sony play-station 5 slim',
    'sony playstations 5 slim',
    'sony play stations 5 slim',
    'sony play-stations 5 slim',
    'sony playstation 4 slim',
    'sony play station 4 slim',
    'sony play-station 4 slim',
    'sony playstations 4 slim',
    'sony play stations 4 slim',
    'sony play-stations 4 slim',
    'sony playstation 3 slim',
    'sony play station 3 slim',
    'sony play-station 3 slim',
    'sony playstations 3 slim',
    'sony play stations 3 slim',
    'sony play-stations 3 slim',
    'sony playstation 2 slim',
    'sony play station 2 slim',
    'sony play-station 2 slim',
    'sony playstations 2 slim',
    'sony play stations 2 slim',
    'sony play-stations 2 slim',
    'sony playstation 1 slim',
    'sony play station 1 slim',
    'sony play-station 1 slim',
    'sony playstations 1 slim',
    'sony play stations 1 slim',
    'sony play-stations 1 slim',
    'sony playstation 5 pro slim',
    'sony play station 5 pro slim',
    'sony play-station 5 pro slim',
    'sony playstations 5 pro slim',
    'sony play stations 5 pro slim',
    'sony play-stations 5 pro slim',
    'sony playstation 4 pro slim',
    'sony play station 4 pro slim',
    'sony play-station 4 pro slim',
    'sony playstations 4 pro slim',
    'sony play stations 4 pro slim',
    'sony play-stations 4 pro slim'
  ]

  const matchedString = genericPlayStationStrings.some(string => lowercasedStrings.includes(string))

  return matchedString
}

export const isRouter = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const routerKeywords = [
    'router',
    'openwrt',
    'access point',
    'tp-link',
    'tp link',
    'tp-link',
    'tp link',
    'tp-link',
    'tp link',
    'tp-link',
    'd-link',
    'd link',
    'd-link',
    'd link',
    'd-link',
    'd link',
    'asus router',
    'asusrouter',
    'asus-router',
    'asus routers',
    'asusrouters',
    'asus-routers',
    'netgear router',
    'netgearrouter',
    'netgear-router',
    'netgear routers',
    'netgearrouters',
    'netgear-routers',
    'tplink router',
    'tplinkrouter',
    'tplink-router',
    'tplink routers',
    'tplinkrouters',
    'tplink-routers'
  ]

  // Check if any of the keywords partially match the input strings
  const containsRouterKeyword = lowercasedStrings.some(string => {
    return routerKeywords.some(keyword => string.includes(keyword))
  })

  return containsRouterKeyword
}

export const isWindows = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  // const isWindows = lowercasedStrings.includes('windows');
  // const isWindowsOs = lowercasedStrings.includes('windows os');

  const genericWindowsStrings = [
    'windows',
    'windows os',
    'windowsos',
    'windows-os',
    'windowsos',
    'windows os',
    'windowsos',
    'windows-os',
    'windows 7',
    'windows 8',
    'windows 8.1',
    'windows 10',
    'windows 11',
    'linux os'
  ]

  return genericWindowsStrings.some(string => lowercasedStrings.includes(string))
}
export const isXbox = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const genericXboxStrings = [
    'xbox',
    'xbox one',
    'xbox series x',
    'xbox series s',
    'xbox series x/s',
    'xbox series x s',
    'xbox one x',
    'xbox one s',
    'xbox one x/s',
    'xbox one x s',
    'xbox one x/s',
    'xbox one x s',
    'Gaming Console',
    'GamingConsole',
    'Gaming-Console',
    'Gaming Consoles',
    'GamingConsoles',
    'Gaming-Consoles',
    'microsoft gaming console',
    'microsoftgaming console',
    'microsoft-gaming console',
    'microsoft gamingconsole',
    'microsoftgamingconsole',
    'microsoft-gamingconsole',
    'microsoft xbox',
    'microsoftxbox',
    'microsoft-xbox',
    'microsoft xbox one',
    'microsoftxbox one',
    'microsoft-xbox one',
    'microsoft xboxone',
    'microsoftxboxone',
    'microsoft-xboxone',
    'sony interactive entertainment inc.',
    'xboxone'
  ]

  const matchedString = genericXboxStrings.some(string => lowercasedStrings.includes(string))

  return matchedString
}

export const isAndroid = (strings: string[]) => {
  const lowercasedStrings = strings
    .filter(item => item !== undefined && item !== null)
    .map(string => string.toLowerCase())

  const genericAndroidStrings = [
    'android',
    'android os',
    'androidos',
    'android-os',
    'androidos',
    'android os',
    'androidos',
    'android-os',
    'android phone',
    'androidphone',
    'android-phone',
    'android phones',
    'androidphones',
    'android-phones',
    'android tablet',
    'androidtablet',
    'android-tablet',
    'android tablets',
    'androidtablets',
    'android-tablets',
    'google android',
    'googleandroid',
    'google-android',
    'google android',
    'googleandroid',
    'google-android',
    'generic android',
    'genericandroid',
    'generic-android',
    'generic android',
    'genericandroid',
    'generic-android',
    'xiaomi android',
    'oneplus-nord-n10-5g'
  ]

  const matchedString = genericAndroidStrings.some(string => lowercasedStrings.includes(string))

  return matchedString

  // const isAndroid = lowercasedStrings.includes('android');
  // const isAndroidOs = lowercasedStrings.includes('android os');
  //
  // if (isAndroid || isAndroidOs) {
  //     return true;
  // } else {
  //     return false;
  // }
}

export const isGoogleHome = (strings: string[]) => {
  const lowercasedStrings = strings
    .filter(item => item !== undefined && item !== null)
    .map(string => string.toLowerCase())

  const genericGoogleHomeStrings = [
    'google home',
    'googlehome',
    'google-home',
    'google homes',
    'googlehomes',
    'google-homes',
    'google home mini',
    'googlehome mini',
    'google-home mini',
    'google home minis',
    'googlehome-minis',
    'google-home-mini'
  ]

  const matchedString = genericGoogleHomeStrings.some(string => lowercasedStrings.includes(string))

  return matchedString
}

const isRaspberryPi = (strings: string[]) => {
  const genericRaspberryPiStrings = [
    'raspberry pi',
    'raspberrypi',
    'raspberry-pi',
    'raspberry pi 1',
    'raspberrypi 1',
    'raspberry-pi 1',
    'raspberry pi1',
    'raspberrypi1',
    'raspberry-pi1',
    'raspberry pi 2',
    'raspberrypi 2',
    'raspberry-pi 2',
    'raspberry pi2',
    'raspberrypi2',
    'raspberry-pi2',
    'raspberry pi 3B',
    'raspberrypi 3B',
    'raspberry-pi 3B',
    'raspberry pi3B',
    'raspberrypi3B',
    'raspberry-pi3B'
  ]

  const matchedString = genericRaspberryPiStrings.some(string => strings.includes(string))

  return matchedString
}

const isGenericGamingConsole = (strings: string[]) => {
  const genericGamingConsole = ['zhuhai dingzhi electronic technology co., ltd', 'gaming console']

  const matchedString = genericGamingConsole.some(string => strings.includes(string))

  return matchedString
}

const isEoHub = (strings: string[]) => {
  const genericEoHubstrings = ['eohub', 'eo hub']

  const matchedString = genericEoHubstrings.some(string => strings.includes(string))

  return matchedString
}

export const handlefingerprint = (strings: string[]) => {
  const lowercasedStrings = strings
    .filter(item => item !== undefined && item !== null)
    .map(string => string.toLowerCase())

  if (isIphone(lowercasedStrings)) {
    return 'iphone'
  } else if (isMacBook(lowercasedStrings)) {
    return 'macbook'
  } else if (isTv(lowercasedStrings)) {
    return 'tv'
  } else if (isAndroidTV(lowercasedStrings)) {
    return 'androidtv'
  } else if (isAppleTv(lowercasedStrings)) {
    return 'appletv'
  } else if (isPrinter(lowercasedStrings)) {
    return 'printer'
  } else if (isRingCamera(lowercasedStrings)) {
    return 'ring-camera'
  } else if (isRouter(lowercasedStrings)) {
    return 'router'
  } else if (isWindows(lowercasedStrings)) {
    return 'windows'
  } else if (isXbox(lowercasedStrings)) {
    return 'xbox'
  } else if (isFireTvStick(lowercasedStrings)) {
    return 'firetvstick'
  } else if (isAndroid(lowercasedStrings)) {
    return 'android'
  } else if (isGenericGamingConsole(lowercasedStrings)) {
    return 'generic-gaming-console'
  } else if (isPlayStation(lowercasedStrings)) {
    return 'playstation'
  } else if (isXunisonDevice(lowercasedStrings)) {
    return 'd50-home'
  } else if (isEoHub(lowercasedStrings)) {
    return 'eo-hub'
  } else if (isRaspberryPi(lowercasedStrings)) {
    return 'raspberrypi'
  } else {
    return 'unknown'
  }
}

export const isFireTvStick = (strings: string[]) => {
  const lowercasedStrings = strings
    .filter(item => item !== undefined && item !== null)
    .map(string => string.toLowerCase())

  const genericFireTvStickStrings = [
    'fire tv stick',
    'firetv stick',
    'fire-tv stick',
    'fire tvstick',
    'firetvstick',
    'fire-tvstick',
    'fire tv stick 4k',
    'firetv stick 4k',
    'fire-tv stick 4k',
    'fire tvstick 4k',
    'firetvstick 4k',
    'fire-tvstick 4k',
    'fire tv stick lite',
    'firetv stick lite',
    'fire-tv stick lite',
    'fire tvstick lite',
    'firetvstick lite',
    'fire-tvstick lite',
    'amazon android',
    'amazon android'
  ]

  return genericFireTvStickStrings.some(string => lowercasedStrings.includes(string))
}

export const isSynologyDevice = (strings: string[]) => {
  const lowercasedStrings = strings
    .filter(item => item !== undefined && item !== null)
    .map(string => string.toLowerCase())

  const genericSynologyStrings = [
    'synology',
    'synology nas',
    'synology-nas',
    'synology nas',
    'synology-nas',
    'synology nas',
    'synology-nas',
    'synology diskstation',
    'synology-diskstation',
    'synology diskstation',
    'synology-diskstation',
    'synology diskstation',
    'synology-diskstation'
  ]

  return genericSynologyStrings.some(string => lowercasedStrings.includes(string))
}
export const isAriaDevice = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const genericHitronDevices = ['aria2210', 'aria']

  const matchedString = genericHitronDevices.some(string => lowercasedStrings.includes(string))

  return matchedString
}
export const isAudio = (strings: string[]) => {
  const lowercasedStrings = strings.map(string => string.toLowerCase())

  const genericHitronDevices = ['audio']

  const matchedString = genericHitronDevices.some(string => lowercasedStrings.includes(string))

  return matchedString
}

export const handleNetworkHealthMapFingerPrinting = (strings: string[]) => {
  const lowercasedStrings = strings
    .filter(item => item !== undefined && item !== null)
    .map(string => string.toLowerCase())
  const iphone = [
    'iphone',
    'iphone 12',
    'iphone 12 pro',
    'iphone 12 pro max',
    'iphone 12 mini',
    'iphone 11',
    'iphone 11 pro',
    'apple iphone',
    'apple iphone 12',
    'apple iphone 12 pro',
    'apple iphone 12 pro max',
    'apple iphone 12 mini',
    'apple iphone 11',
    'apple iphone 11 pro',
    'apple iphone 12',
    'apple iphone 12 pro',
    'apple iphone 12 pro max',
    'apple iphone 12 mini',
    'apple iphone 11',
    'apple iphone 11 pro',
    'iphone se',
    'apple iphone se',
    'apple iphone se 2020',
    'apple iphone se2',
    'apple iphone se 2',
    'apple iphone se2020',
    'apple iphone se-2020',
    'apple iphone se-2',
    'apple iphone se2',
    'iphone x',
    'apple iphone x',
    'apple iphone x',
    'apple iphone x',
    'apple iphone x',
    'apple iphone x',
    'apple iphone x'
  ]

  if (isTv(lowercasedStrings)) {
    return 'tv'
  } else if (isFireTvStick(lowercasedStrings)) {
    return 'firetvstick'
  } else if (isAndroid(lowercasedStrings)) {
    return 'android'
  } else if (isIphone(lowercasedStrings)) {
    return 'iphone'
  } else if (isIpad(lowercasedStrings)) {
    return 'ipad'
  } else if (isXunisonDevice(lowercasedStrings)) {
    return 'd50-home'
  } else if (isRaspberryPi(lowercasedStrings)) {
    return 'raspberrypi'
  } else if (isMacBook(lowercasedStrings)) {
    return 'macbook'
  } else if (isPrinter(lowercasedStrings)) {
    return 'printer'
  } else if (isRingCamera(lowercasedStrings)) {
    return 'ring-camera'
  } else if (isXbox(lowercasedStrings)) {
    return 'xbox'
  } else if (isPlayStation(lowercasedStrings)) {
    return 'playstation'
  } else if (isWindows(lowercasedStrings)) {
    return 'windows'
  } else if (isTv(lowercasedStrings)) {
    return 'tv'
  } else if (isSynologyDevice(lowercasedStrings)) {
    return 'synology'
  } else if (isGenericGamingConsole(lowercasedStrings)) {
    return 'generic-gaming-console'
  } else if (isAriaDevice(lowercasedStrings)) {
    return 'aria'
  } else {
    return 'unknown'
  }
}

export const newhandleNetworkHealthMapFingerPrinting = (strings: string[]) => {
  const lowercasedStrings = strings
    .filter(item => item !== undefined && item !== null)
    .map(string => string.toLowerCase())
  if (process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer') {
    if (isTv(lowercasedStrings)) {
      return 'tv'
    } else if (isFireTvStick(lowercasedStrings)) {
      return 'firetvstick'
    } else if (isRouter(lowercasedStrings)) {
      return 'router'
    } else if (isAndroid(lowercasedStrings)) {
      return 'android'
    } else if (isIphone(lowercasedStrings)) {
      return 'mobile'
    } else if (isIpad(lowercasedStrings)) {
      return 'ipad'
    }
    //  else if (isXunisonDevice(lowercasedStrings)) {
    //   return 'd50-home'
    // }
    else if (isRaspberryPi(lowercasedStrings)) {
      return 'RaspberryPi'
    } else if (isMacBook(lowercasedStrings)) {
      return 'macbook'
    } else if (isPrinter(lowercasedStrings)) {
      return 'printer'
    } else if (isRingCamera(lowercasedStrings)) {
      return 'Ring-securitycamera'
    } else if (isXbox(lowercasedStrings)) {
      return 'xbox'
    } else if (isPlayStation(lowercasedStrings)) {
      return 'playstation'
    } else if (isWindows(lowercasedStrings)) {
      return 'windows'
    } else if (isSynologyDevice(lowercasedStrings)) {
      return 'synology'
    } else if (isGenericGamingConsole(lowercasedStrings)) {
      return 'generic-gaming-console'
    } else if (isAriaDevice(lowercasedStrings)) {
      return 'aria'
    } else if (isAudio(lowercasedStrings)) {
      return 'audio'
    } else {
      return 'unknown'
    }
  } else {
    if (isTv(lowercasedStrings)) {
      return 'TV'
    }
    // else if (isFireTvStick(lowercasedStrings)) {
    //   return 'firetvstick'
    // }
    else if (isRouter(lowercasedStrings)) {
      return 'router'
    } else if (isAndroid(lowercasedStrings)) {
      return 'mobile'
    } else if (isIphone(lowercasedStrings)) {
      return 'mobile'
    }
    // else if (isIpad(lowercasedStrings)) {
    //   return 'ipad'
    // }
    //  else if (isXunisonDevice(lowercasedStrings)) {
    //   return 'd50-home'
    // }
    else if (isRaspberryPi(lowercasedStrings)) {
      return 'RaspberryPi'
    } else if (isMacBook(lowercasedStrings)) {
      return 'laptop'
    } else if (isPrinter(lowercasedStrings)) {
      return 'printer'
    } else if (isRingCamera(lowercasedStrings)) {
      return 'Ring-securitycamera'
    }
    // else if (isXbox(lowercasedStrings)) {
    //   return 'xbox'
    // } else if (isPlayStation(lowercasedStrings)) {
    //   return 'playstation'
    // }
    // else if (isWindows(lowercasedStrings)) {
    //   return 'windows'
    // }
    // else if (isSynologyDevice(lowercasedStrings)) {
    //   return 'synology'
    // }
    else if (isGenericGamingConsole(lowercasedStrings)) {
      return 'generic-gaming-console'
    }
    // else if (isAriaDevice(lowercasedStrings)) {
    //   return 'aria'
    // }
    //  else if (isAudio(lowercasedStrings)) {
    //   return 'audio'
    // }
    else {
      return 'mobile'
    }
  }
}
