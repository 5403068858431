/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { notifyError } from '../../components/Toaster'
import xcpemSec from '../../axiosInstances/xcpemSec'
import xcpemCyber from '../../axiosInstances/xcpemCyber'
import axios from 'axios'
import { useState } from 'react'

const host = `https://${process.env.REACT_APP_IOTCORE}`
const baseUrl = `${host}`

export const useDeviceControlCount = (
  gwuid: string,
  from?: string,
  event?: string | any
): {
  data: any[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
  isSuccess: boolean
} => {
  const [data, setData] = useState<any | undefined>([])

  const { isLoading, error, isFetching, isFetched, refetch, isSuccess } = useQuery(
    ['device-control-count'],
    async () => {
      if (!gwuid || gwuid === '') return undefined

      try {
        const response = await xcpemSec.get<any[]>(
          `/rpc/webapp_device_control_count?_gw_uid=${gwuid}&_timestamp_from=${from}&_event_type=${encodeURIComponent(
            event
          )}`
        )

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`device-control-count: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData([])
    await queryClient.invalidateQueries(['device-control-count'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch, isSuccess }
}
export const useDeviceControlEventCount = (
  from?: string,
  event?: string | any
): {
  data: any[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
  isSuccess: boolean
} => {
  const [data, setData] = useState<any | undefined>([])

  const { isLoading, error, isFetching, isFetched, refetch, isSuccess } = useQuery(
    ['device-control-event-count'],
    async () => {
      const email: any = localStorage.getItem('email') || ''

      try {
        const response = await xcpemCyber.get<any[]>(
          `/api/${
            process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer'
              ? 'v4/ui/parental-control/eventlog/count'
              : 'v1/panel/eventlogcount'
          }?email=${encodeURIComponent(email)}&${
            process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer'
              ? `from_date=${from}Z`
              : `timestamp_from=${from}`
          }&event_type=${encodeURIComponent(event)}`
        )
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`device-control-count: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData([])
    await queryClient.invalidateQueries(['device-control-event-count'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch, isSuccess }
}
export const usefirmware = (
  modal?: string
): {
  data: any[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>([])
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['fw_versions'],
    async () => {
      try {
        if (!modal) return

        const response = await xcpemSec.get(
          `${baseUrl}/webapp_list_fw_versions?device_model=eq.${encodeURIComponent(modal)}`
        )

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`fw_versions: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData([])
    await queryClient.invalidateQueries(['fw_versions'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}

export const useDeviceControlLogs = (
  currentPage: any,
  activeGw_Uid: string,
  limit?: any,
  time?: string,
  event?: string | any
): {
  data: [] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
  isSuccess: boolean
} => {
  const [data, setData] = useState<any | undefined>([])

  const { isLoading, error, isFetching, isFetched, refetch, isSuccess } = useQuery(
    ['device-control-admin-log'],
    async () => {
      const gw_uid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      if (gw_uid === null) return []

      const endpoint = `/webapp_dashboard_admin_log?gw_uid=eq.${gw_uid}&limit=${limit}&offset=${currentPage}&timestamp=gt.${time}&event=not.is.null&event_type=eq.${encodeURIComponent(
        event
      )}`
      try {
        const response = await xcpemSec.get<[]>(endpoint)
        // const response = await xcpemSec.get<[]>(
        //   '/webapp_dashboard_admin_log?gw_uid=eq.gw760f86604bf6c004dbeb841c645ca198&timestamp=gt.2022-11-21T04:19:09.071&event=eq.Admin GW DE-PROVISIONED&limit=10&offset=0'
        // )
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`device-control-admin-log: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData([])
    await queryClient.invalidateQueries(['device-control-admin-log'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch, isSuccess }
}

export const useDeviceControlEventLogs = (
  currentPage: any,
  limit?: any,
  time?: string,
  event?: string | any
): {
  data: [] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  isSuccess: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>([])
  const { isLoading, error, isFetching, isFetched, isSuccess, refetch } = useQuery(
    ['device-control-admin-event-log'],
    async () => {
      const email: any = localStorage.getItem('email') || ''

      const endpoint = `/api/${
        process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer'
          ? 'v4/ui/parental-control/eventlog/details'
          : 'v1/panel/eventlogdetails'
      }?email=${encodeURIComponent(email)}&${
        process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer'
          ? `from_date=${time}Z&total=true&offset=${currentPage}&limit=${limit}`
          : `offset=${limit}&page=${currentPage + 1}&timestamp_from=${time}`
      }&event_type=${encodeURIComponent(event)}`
      try {
        const response = await xcpemCyber.get<[]>(endpoint)
        // const response = await xcpemSec.get<[]>(
        //   '/webapp_dashboard_admin_log?gw_uid=eq.gw760f86604bf6c004dbeb841c645ca198&timestamp=gt.2022-11-21T04:19:09.071&event=eq.Admin GW DE-PROVISIONED&limit=10&offset=0'
        // )
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`device-control-admin-log: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData([])
    await queryClient.invalidateQueries(['device-control-admin-event-log'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, isSuccess, refetch: handleRefetch }
}
export const useDeviceControlFailLogs = (
  currentPage: any,
  activeGw_Uid: string,
  limit?: any,
  // ltTime?: any,
  time?: string,
  event?: string | any
): {
  data: [] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  isSuccess: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>([])

  const { isLoading, error, isFetching, isFetched, isSuccess, refetch } = useQuery(
    ['device-control-fail-admin-log'],
    async () => {
      const gw_uid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      if (gw_uid === null) return []

      const endpoint = `/webapp_dashboard_admin_log?gw_uid=eq.${gw_uid}&limit=${limit}&offset=${currentPage}&timestamp=gt.${time}&event=is.null&event_type=eq.${encodeURIComponent(
        event
      )}`
      try {
        const response = await xcpemSec.get<[]>(endpoint)
        // const response = await xcpemSec.get<[]>(
        //   '/webapp_dashboard_admin_log?gw_uid=eq.gw760f86604bf6c004dbeb841c645ca198&timestamp=gt.2022-11-21T04:19:09.071&event=eq.Admin GW DE-PROVISIONED&limit=10&offset=0'
        // )
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`device-control-admin-log: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData([])
    await queryClient.invalidateQueries(['device-control-fail-admin-log'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, isSuccess, refetch: handleRefetch }
}
export const useDeviceControlActivityLogs = (
  currentPage: any,
  activeGw_Uid: string,
  limit?: any,
  time?: string,
  event?: string
): {
  data: [] | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['device-control-activity-log'],
    async () => {
      const gw_uid = !!activeGw_Uid ? activeGw_Uid : localStorage.getItem('gwuid')

      if (gw_uid === null) return []

      const endpoint = `/webapp_dashboard_activity_log?gw_uid=eq.${gw_uid}&limit=${limit}&offset=${currentPage}&timestamp=gt.${time}event=eq.Gateway REBOOTED`
      try {
        const response = await xcpemSec.get<[]>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`device-control-activity-log: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
// export const useModalReset = () => {
//   const postRequest = async ({ url, payload }: any) => {
//     try {
//       const response = await xcpemSec.post(`${baseUrl}/rpc/${url}`, payload)
//       return response.data
//     } catch (error: any) {
//       notifyError(`Factory Reset Token: ${error?.response?.data?.message || error?.message}`)
//     }
//   }

//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const { mutate, isLoading, error } = useMutation(postRequest)

//   return { mutate, isLoading, error }
// }

const usePostModalReset = async ({ url, payload }: any) => {
  const response = await xcpemSec.post(`${baseUrl}/rpc/${url}`, payload)
  return response.data
}

export const useModalReset = () => {
  return useMutation(usePostModalReset)
}
const usePostSendMail = async ({ mailPayload }: any) => {
  const access_token = localStorage.getItem('access_token')
  // const response = await xcpemSec.post(`${baseUrl}/api/wifi/reset-wifi-password`, mailPayload)
  let url = `${baseUrl}/api/v1/wifi/reset-password/mail`
  if (process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer') {
    url = `${baseUrl}/api/v1/notification/wifi/reset-password`
  }
  const response = await axios.post(`${url}`, mailPayload, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  })
  return response.data
}

export const useSendMail = () => {
  return useMutation(usePostSendMail)
}
const usePostSendMailPin = async ({ mailPayload }: any) => {
  const access_token = localStorage.getItem('access_token')
  let url = `${baseUrl}/api/v1/user/reset-pin/mail`
  if (process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer') {
    url = `${baseUrl}/api/v1/notification/account/reset-pin`
  }
  const response = await axios.post(`${url}`, mailPayload, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  })
  return response.data
}

export const useSendMailPin = () => {
  return useMutation(usePostSendMailPin)
}
export const useDeleteSupervisions = () => {
  const deleteRequest = async ({ payloadSupervision }: any) => {
    try {
      if (process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer') {
        const response = await xcpemCyber.delete(
          `https://${process.env.REACT_APP_CYBER}/api/v4/ui/parental-control/supervisions/`,
          { data: payloadSupervision }
        )
        return response.data
      } else {
        return []
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        throw new Error('Failed to delete resource')
      }
    }
  }
  const { mutate, isLoading, error } = useMutation(deleteRequest)

  return { mutate, isLoading, error }
}
export const useResetAccount = () => {
  const postRequest = async ({ url, email, payloadData }: any) => {
    if (process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer') {
      delete payloadData.confirm_pincode
      payloadData.email = email

      try {
        const response = await xcpemCyber.post(
          `https://${process.env.REACT_APP_CYBER}/api/v4/ui/parental-control/${url}`,
          payloadData
        )

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`${error?.response?.data?.message || error?.message}`)
          throw new Error(error?.response?.data?.message || error?.message)
        }
      }
    } else {
      try {
        const response = await xcpemCyber.post(
          `https://${process.env.REACT_APP_CYBER}/api/v1/panel/${url}?email=${encodeURIComponent(email)}`,
          payloadData
        )

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`${error?.response?.data?.message || error?.message}`)
          throw new Error(error?.response?.data?.message || error?.message)
        }
      }
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(postRequest)

  return { mutate, isLoading, error }
}
