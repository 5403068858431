import ThreatsTileStaging from '../staging/threats-tile/threats-tile'
import ThreatsTileCommon from '../common/threats-tile/threats-tile'
const ParentalControlTable: React.FC<any> = ({
  devices,
  onChange,
  useMac,
  useDeviceInfo,
  reCall,
  filteringDevice = '*'
}) => {
  return (
    <>
      {process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer' ? (
        <ThreatsTileStaging
          devices={devices}
          onChange={onChange}
          useMac={useMac}
          useDeviceInfo={useDeviceInfo}
          reCall={reCall}
          filteringDevice={filteringDevice}
        />
      ) : (
        <ThreatsTileCommon
          devices={devices}
          onChange={onChange}
          useMac={useMac}
          useDeviceInfo={useDeviceInfo}
          reCall={reCall}
          filteringDevice={filteringDevice}
        />
      )}
    </>
  )
}

export default ParentalControlTable
