import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import axios from 'axios'
import { NetworkHealthMapRootState } from './types'
import { KEYCLOAK_CONFIG, readOnlyApiUrl } from '../../app-config'
import xcpemSec from '../../axiosInstances/xcpemSec'
import { notifyError } from '../../components/Toaster'
import xcpemReadOnlySec from '../../axiosInstances/xcpemReadOnlySec'

interface NetworkHealthMapProps {
  gwUid: string
  direction?: string
  time?: string
}

export const getNetworkMapDevices = ({
  gwUid
}: NetworkHealthMapProps): ThunkAction<void, NetworkHealthMapRootState, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<NetworkHealthMapRootState, unknown, AnyAction>) => {
    if (!gwUid) return
    try {
      dispatch({ type: 'GET_NETWORK_MAP_DEVICES_REQUEST' }) // Set loading to true

      const response = await xcpemReadOnlySec.get(
        `/rpc/webapp_wifi_network_health_map_dev_type?_gw_uid=${gwUid}&_direction=back&_time=`
      )
      const networkHealthMap = response.data
      dispatch({ type: 'GET_NETWORK_MAP_DEVICES_SUCCESS', payload: networkHealthMap })
    } catch (error: any) {
      dispatch({
        type: 'GET_NETWORK_MAP_DEVICES_FAILURE',
        error: error?.response?.status === 401 ? error?.response?.status : error.message
      })
      if (error?.response?.status === 401) {
        return error?.response?.status

        // localStorage.clear()
        //  localStorage.clear()
        //  window.location.href = '/#/login'
      } else {
        notifyError(`Network-map device: ${error?.response?.data?.message || error?.message}`)
      }
    }
  }
}

export const getNetworkDevices = (
  gwuid: string,
  mac: string,
  from: string
): ThunkAction<void, NetworkHealthMapRootState, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<NetworkHealthMapRootState, unknown, AnyAction>) => {
    const endpoint = `/rpc/webapp_network_stats?_gw_uid=${gwuid}&_mac=${mac}&_timestamp_from=${from}`

    try {
      dispatch({ type: 'GET_NETWORK_DEVICES_REQUEST' }) // Set loading to true
      const response = await xcpemSec.get<any[]>(endpoint)
      const networkHealthMap = response.data
      dispatch({ type: 'GET_NETWORK_DEVICES_SUCCESS', payload: networkHealthMap })
      dispatch({
        type: 'GET_NETWORK_MAP_DEVICES_FAILURE',
        error: null
      })
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status

        // localStorage.clear()
        //  localStorage.clear()
        //  window.location.href = '/#/login'
      } else {
        notifyError(`Network devices: ${error?.response?.data?.message || error?.message}`)
      }
    }
  }
  // const {data, isLoading, error, isFetching, isFetched, refetch} = useQuery(['network-stats']
  // , async () => {

  //     const endpoint = `/rpc/webapp_network_stats?_gw_uid=${gwuid}&_mac=${mac}&_timestamp_from=${from}`

  //     try {
  //         const response = await xcpemSec.get<any[]>(endpoint)
  //         return response.data
  //     } catch (error) {
 
  //     }
  // })

  // return {data, isLoading, error, isFetching, isFetched, refetch}
}
