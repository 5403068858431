import { WebMonitoring, TimeSupervision, ConnectedDevices } from '../../models/Xcpem'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import xcpemCyber from '../../axiosInstances/xcpemCyber'
import { notifyError, notifySuccess } from '../../components/Toaster'
import xcpemSec from '../../axiosInstances/xcpemSec'
import { cyberApiUrl } from '../../app-config'
import { useState } from 'react'
import xcpemReadOnlySec from '../../axiosInstances/xcpemReadOnlySec'

interface VerifyPinProps {
  pincode: string
}

const useDeletePCSetting = (
  deviceId: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  isSuccess: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch, isSuccess } = useQuery(
    ['pc-delete-setting'],
    async () => {
      const email = localStorage.getItem('email')

      if (!email) return undefined

      try {
        const email: any = localStorage.getItem('email')
        const res = await xcpemSec.delete(
          `${cyberApiUrl}/api/v1/panel/pc_settings?email=${encodeURIComponent(email)}&device_id=${deviceId}`
        )
        console.log('=====>>@@', res)
        return res.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`PC Setting: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch, isSuccess }
}

export const useWebMonitoring = (
  from: string,
  email: string,
  deviceFilter?: any
): {
  data: any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['web-monitoring'],
    async () => {
      const endpoint = `/api/v1/panel/supervision/web?from_date=${from}&email=${encodeURIComponent(email)}${
        deviceFilter && deviceFilter !== '*' ? `&device_id=${deviceFilter}` : ''
      }`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Web monitoring: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useTimeSupervision = (
  from: string,
  email: string,
  deviceFilter?: any
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['time-monitoring'],
    async () => {
      const endpoint = `/api/v1/panel/supervision/time?from_date=${from}&email=${encodeURIComponent(email)}${
        deviceFilter && deviceFilter !== '*' ? `&device_id=${deviceFilter}` : ''
      }`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Time monitoring: ${error?.response?.data?.message || error?.message}`)
          throw new Error('Failed to fetch web monitoring data')
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useSocialSupervision = (
  from: string,
  email: string,
  deviceFilter?: any
): {
  data: any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>([])
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['social-monitoring'],
    async () => {
      if (from && email) {
        const endpoint = `/api/v1/panel/supervision/social?from_date=${from}&email=${encodeURIComponent(email)}${
          deviceFilter && deviceFilter !== '*' ? `&device_id=${deviceFilter}` : ''
        }`
        try {
          const response = await xcpemCyber.get<any>(endpoint)
          return response.data || undefined
        } catch (error: any) {
          if (error?.response?.status === 401) {
            return error?.response?.status
          } else {
            notifyError(`Social monitoring: ${error?.response?.data?.message || error?.message}`)
            throw new Error('Failed to fetch social monitoring data')
          }
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['social-monitoring'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}

export const useGeneralizedSupervision = (
  from: string,
  email: string,
  type: 'web' | 'time' | 'social' | 'devices'
): {
  data: WebMonitoring | TimeSupervision | ConnectedDevices | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['generalized-supervision'],
    async () => {
      const endpointType = type !== 'devices' ? `supervision/${type}` : `devices`

      const endpoint = `/api/v1/panel/${endpointType}?from_date=${from}&email=${encodeURIComponent(email)}`
      try {
        const response = await xcpemCyber.get<WebMonitoring | TimeSupervision | ConnectedDevices>(endpoint)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Supervision: ${error?.response?.data?.message || error?.message}`)
          throw new Error(`Failed to fetch ${type}`)
        }
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useDeviceSupervisionActive = (
  device_list: number
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['is-device-monitoring-enabled'],
    async () => {
      const endpoint = `/api/v1/parental_control/devices/monitor?device_list=${device_list}`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Devices monitor:${error?.response?.data?.message || error?.message}`)

          throw new Error(`Failed to fetch device monitoring status`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useTurnOffSafeSearch = (): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['turn-off-safe-search'],
    async () => {
      const email = localStorage.getItem('email')

      if (!email) return undefined

      const url = `/api/v1/panel/family_shield/safe_search?email=${encodeURIComponent(email)}`

      try {
        const response = await xcpemCyber.get<any>(url)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Safe search: ${error?.response?.data?.message || error?.message}`)

          throw new Error(`Failed to update services`)
        }
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

// verify parental PIN

const verifyParentalPin = async ({ pincode }: any) => {
  const body = new FormData()
  const email = localStorage.getItem('email')

  if (!email) return undefined
  body.append('pincode', pincode)
  body.append('customer_email', email)
  const pin_verificaiton_url = `/api/v1/parental_control/customer-pin/verify`
  const response = await xcpemCyber.post(pin_verificaiton_url, body)

  return response.data
}

export const usePinVerification = () => {
  return useMutation(verifyParentalPin)
}

export const useSentMail = (): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['sent-pc-mail'],
    async () => {
      const email = localStorage.getItem('email')

      if (!email) return undefined
      const fr: string = process.env.REACT_APP_REALM || ''
      const payload = {
        from: fr,
        email: email
      }
      const pin_verificaiton_url = `/api/v1/admin/user-login/notification/mail`

      try {
        const response = await xcpemReadOnlySec.post(pin_verificaiton_url, payload)

        return response || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          return error?.response
          // notifyError(`sent-pc-mail: ${error?.response?.data?.message || error?.message}`)
          // throw new Error('Failed to sent mail')
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}
// ================================
export const useParentaDeletePCSetting = (deviceId: string) => {
  const deviceAPI =
    process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer'
      ? useStagingDeletePCSetting
      : useDeletePCSetting
  return deviceAPI(deviceId)
}
const useStagingDeletePCSetting = (
  deviceId: string
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  isSuccess: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch, isSuccess } = useQuery(
    ['sent-pc-delete-setting'],
    async () => {
      const email = localStorage.getItem('email')

      if (!email) return undefined

      try {
        const res = await xcpemSec.post(`${cyberApiUrl}/api/v4/ui/parental-control/device/reset`, {
          email: email,
          device_mac: deviceId
        })
        notifySuccess(res?.data?.message)

        const modifyRes = {
          http_code: res?.status,
          status: res?.data?.message
        }
        return modifyRes
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`PC Setting: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch, isSuccess }
}
// const useStagingDeletePCSetting = () => {
//   const postRequest = async (deviceId?: string) => {
//     try {
//       const email: any = localStorage.getItem('email')
//       await xcpemSec.post(`${cyberApiUrl}/api/v4/ui/parental-control/device/reset`, {
//         email: email,
//         device_mac: deviceId
//       })
//       notifySuccess('Successfully removed PC settings')
//       // return response.data
//     } catch (error: any) {
//       if (error?.response?.status === 401) {
//         return error?.response?.status
//       } else {
//         notifyError(`PC Setting: ${error?.response?.data?.message || error?.message}`)
//       }
//     }
//   }

//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const { mutate, isLoading, error, isSuccess } = useMutation(postRequest)

//   return { mutate, isLoading, error, isSuccess }
// }

export const useStagingMonitoring = (
  from: string,
  email: string,
  deviceFilter?: any,
  offset?: any,
  limit?: any,
  category_id?: any
): {
  data: any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['scalling-monitoring'],
    async () => {
      const endpoint = `/api/v4/ui/parental-control/supervisions/monitoring/details?from_date=${from}&email=${encodeURIComponent(
        email
      )}${
        deviceFilter && deviceFilter !== '*' ? `&device_mac=${deviceFilter}` : ''
      }&limit=${limit}&offset=${offset}&total=true&category_id=${category_id || 0}`

      try {
        const response = await xcpemCyber.get<any>(endpoint)

        return [{ data: response.data }, { total: response.headers['content-range'] }]
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Web monitoring: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useStagingWebMonitoring = (
  from: string,
  email: string,
  deviceFilter?: any,
  offset?: any,
  limit?: any,
  category_id?: any
): {
  data: any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['web-scalling-monitoring'],
    async () => {
      const endpoint = `/api/v4/ui/parental-control/supervisions/web/details?from_date=${from}&email=${encodeURIComponent(
        email
      )}${
        deviceFilter && deviceFilter !== '*' ? `&device_mac=${deviceFilter}` : ''
      }&limit=${limit}&offset=${offset}&total=true&category_id=${category_id || 0}`

      try {
        const response = await xcpemCyber.get<any>(endpoint)

        return [{ data: response.data }, { total: response.headers['content-range'] }]
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Web monitoring: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useStagingWebMonitoringTotal = (
  from: string,
  email: string,
  deviceFilter?: any
): {
  data: any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['web-scalling-monitoring-total'],
    async () => {
      const endpoint = `/api/v4/ui/parental-control/supervisions/web/total?from_date=${from}&email=${encodeURIComponent(
        email
      )}${deviceFilter && deviceFilter !== '*' ? `&device_mac=${deviceFilter}` : ''}`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Web monitoring: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useStagingTimeSupervision = (
  from: string,
  email: string,
  deviceFilter?: any,
  offset?: any,
  limit?: any
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['time-scalling-monitoring'],
    async () => {
      const endpoint = `/api/v4/ui/parental-control/supervisions/time/details?from_date=${from}&email=${encodeURIComponent(
        email
      )}${
        deviceFilter && deviceFilter !== '*' ? `&device_mac=${deviceFilter}` : ''
      }&limit=${limit}&offset=${offset}&total=true`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return [{ data: response.data }, { total: response.headers['content-range'] }]
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Time monitoring: ${error?.response?.data?.message || error?.message}`)
          throw new Error('Failed to fetch web monitoring data')
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useStagingTimeSupervisionChart = (
  from: string,
  email: string,
  deviceFilter?: any,
  filterLabel?: any
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['time-scalling-monitoring-chart'],
    async () => {
      const url = deviceFilter && deviceFilter !== '*' ? '/groupbyperiod' : '/total'
      const extraFilter = deviceFilter && deviceFilter !== '*' ? `&period=day` : ''
      // deviceFilter && deviceFilter !== '*' ? `&period=${filterLabel === 'today' ? 'hour' : 'day'}` : ''

      const endpoint = `/api/v4/ui/parental-control/supervisions/time${url}?from_date=${from}&email=${encodeURIComponent(
        email
      )}${deviceFilter && deviceFilter !== '*' ? `&device_mac=${deviceFilter}` : ''}${extraFilter}`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Time monitoring: ${error?.response?.data?.message || error?.message}`)
          throw new Error('Failed to fetch web monitoring data')
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useStagingSocialSupervision = (
  from: string,
  email: string,
  deviceFilter?: any,
  offset?: any,
  limit?: any
): {
  data: any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>([])
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['social-scalling-monitoring'],
    async () => {
      if (from && email) {
        const endpoint = `/api/v4/ui/parental-control/supervisions/social/details?from_date=${from}&email=${encodeURIComponent(
          email
        )}${
          deviceFilter && deviceFilter !== '*' ? `&device_mac=${deviceFilter}` : ''
        }&limit=${limit}&offset=${offset}&total=true`
        try {
          const response = await xcpemCyber.get<any>(endpoint)
          return [{ data: response.data }, { total: response.headers['content-range'] }]
        } catch (error: any) {
          if (error?.response?.status === 401) {
            return error?.response?.status
          } else {
            notifyError(`Social monitoring: ${error?.response?.data?.message || error?.message}`)
            throw new Error('Failed to fetch social monitoring data')
          }
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['social-scalling-monitoring'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}
export const useStagingSocialSupervisionTotal = (
  from: string,
  email: string,
  deviceFilter?: any
): {
  data: any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>([])
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['social-scalling-monitoring-total'],
    async () => {
      if (from && email) {
        const endpoint = `/api/v4/ui/parental-control/supervisions/social/total?from_date=${from}&email=${encodeURIComponent(
          email
        )}${deviceFilter && deviceFilter !== '*' ? `&device_mac=${deviceFilter}` : ''}`
        try {
          const response = await xcpemCyber.get<any>(endpoint)
          return response.data
        } catch (error: any) {
          if (error?.response?.status === 401) {
            return error?.response?.status
          } else {
            notifyError(`Social monitoring: ${error?.response?.data?.message || error?.message}`)
            throw new Error('Failed to fetch social monitoring data')
          }
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['social-scalling-monitoring-total'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}
const useParentalConnectedDevices = (
  from: string,
  email: string | null
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['pcconnecteddevices'],
    async () => {
      const localEmail = email || localStorage.getItem('email')
      if (!localEmail && localEmail === null) return undefined

      const endpoint = `/api/v2/panel/devices?from_date=${from}&email=${encodeURIComponent(localEmail)}`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`PC connected devices: ${error?.response?.data?.message || error?.message}`)
          throw new Error('Failed to fetch connected devices')
        }
      }
    },
    { enabled: false, staleTime: 0, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}
const useStagingParentalConnectedDevices = (
  from: string,
  email: string | null
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['pc-scallingconnecteddevices'],
    async () => {
      const localEmail = email || localStorage.getItem('email')
      if (!localEmail || localEmail === null) return undefined

      const endpoint = `/api/v4/ui/parental-control/devices?from_date=${from}&email=${encodeURIComponent(localEmail)}`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data || undefined || []
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`PC connected devices: ${error?.response?.data?.message || error?.message}`)
          throw new Error('Failed to fetch connected devices')
        }
      }
    },
    { enabled: false, staleTime: 0, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useConditionalParentalConnectedDevices = (from: string, email: string | null) => {
  const deviceAPI =
    process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer'
      ? useStagingParentalConnectedDevices
      : useParentalConnectedDevices
  return deviceAPI(from, email)
}

// export const useConditionalParentalConnectedDevices

export const useStagingGeneralizedSupervision = (
  from: string,
  email: string,
  type: 'web' | 'time' | 'social' | 'devices'
): {
  data: WebMonitoring | TimeSupervision | ConnectedDevices | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['generalized-scalling-supervision'],
    async () => {
      const endpointType = type !== 'devices' ? `supervision/${type}` : `devices`

      const endpoint = `/api/v1/panel/${endpointType}?from_date=${from}&email=${encodeURIComponent(email)}`
      try {
        const response = await xcpemCyber.get<WebMonitoring | TimeSupervision | ConnectedDevices>(endpoint)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Supervision: ${error?.response?.data?.message || error?.message}`)
          throw new Error(`Failed to fetch ${type}`)
        }
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

export const useStagingDeviceSupervisionActive = (
  device_list: number
): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['is-scalling-device-monitoring-enabled'],
    async () => {
      const endpoint = `/api/v1/parental_control/devices/monitor?device_list=${device_list}`
      try {
        const response = await xcpemCyber.get<any>(endpoint)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Devices monitor:${error?.response?.data?.message || error?.message}`)

          throw new Error(`Failed to fetch device monitoring status`)
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useStagingTurnOffSafeSearch = (): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['turn-scalling-off-safe-search'],
    async () => {
      const email = localStorage.getItem('email')

      if (!email) return undefined

      const url = `/api/v1/panel/family_shield/safe_search?email=${encodeURIComponent(email)}`

      try {
        const response = await xcpemCyber.get<any>(url)
        return response.data || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Safe search: ${error?.response?.data?.message || error?.message}`)

          throw new Error(`Failed to update services`)
        }
      }
    },
    { enabled: false, cacheTime: 0, retry: 0 }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}

// verify parental PIN

const verifyStagingParentalPin = async ({ pincode }: any) => {
  // const body = new URLSearchParams()
  const body = new FormData()
  const email = localStorage.getItem('email')

  if (!email) return undefined
  body.append('pincode', pincode)
  // body.append('customer_email', email)
  body.append('email', email)
  const pin_verificaiton_url = `/api/v4/ui/parental-control/pin/verify`
  const response = await xcpemCyber.post(pin_verificaiton_url, body)

  return response.data
}

export const useStagingPinVerification = () => {
  return useMutation(verifyStagingParentalPin)
}

export const useStagingSentMail = (): {
  data: any | undefined
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['sent-scalling-pc-mail'],
    async () => {
      const email = localStorage.getItem('email')

      if (!email) return undefined
      const fr: string = process.env.REACT_APP_REALM || ''
      const payload = {
        from: fr,
        email: email
      }
      const pin_verificaiton_url = `/api/v1/notification/admin/user-login`

      try {
        const response = await xcpemSec.post(pin_verificaiton_url, payload)

        return response || undefined
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          return error?.response
          // notifyError(`sent-pc-mail: ${error?.response?.data?.message || error?.message}`)
          // throw new Error('Failed to sent mail')
        }
      }
    },
    {
      enabled: false,
      cacheTime: 0,
      retry: 0
    }
  )
  return { data, isLoading, error, isFetching, isFetched, refetch }
}
