import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // Default: localStorage
import { thunk } from 'redux-thunk'

// Import your reducers
import customerStatisticsReducer from './customerStatistics/reducer'
import dashboardCountReducer from './dashboard/reducers'
import dashboardProblemsReducer from './dashboardProblems/reducers'
import deviceInformationReducer from './deviceInformation/reducers'
import deviceStatisticsReducer from './deviceStatistics/reducer'
import globalSearchReducer from './GlobalSearch/reducer'
import networkHealthMapReducer from './networkHealthMap/reducer'
import userReducer from './userDefaultData/userDefaultData'

// Persist Config
const persistConfig = {
  key: 'root', // Key for the root of the state object
  storage, // Storage engine (e.g., localStorage)
  whitelist: [
    'dashboardCount',
    'dashboardProblems',
    'deviceInformation',
    'globalSearch',
    'deviceStatistics',
    'customerStatistics',
    'networkHealthMap',
    'user'
  ] // Specify which reducers you want to persist
}

// Combine Reducers
const rootReducer: any = combineReducers({
  dashboardCount: dashboardCountReducer,
  dashboardProblems: dashboardProblemsReducer,
  deviceInformation: deviceInformationReducer,
  globalSearch: globalSearchReducer,
  deviceStatistics: deviceStatisticsReducer,
  customerStatistics: customerStatisticsReducer,
  networkHealthMap: networkHealthMapReducer,
  user: userReducer
})

// Create Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)

// Configure Store
const store: any = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check for Redux Persist
      thunk
    })
})

// Create Persistor
const persistor = persistStore(store)

export { store, persistor }
