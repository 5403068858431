// dashboardCount.tsx

import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import xcpemSec from '../../axiosInstances/xcpemSec'
import { notifyError } from '../../components/Toaster'
import { DashboardRootState } from './types'

export const getDashboardCount = (): ThunkAction<void, DashboardRootState, unknown, AnyAction> => {
  return async (dispatch: ThunkDispatch<DashboardRootState, unknown, AnyAction>) => {
    try {
      dispatch({ type: 'GET_DASHBOARD_COUNT_REQUEST' }) // Set loading to true

      const response = await xcpemSec.get<any[]>(`/rpc/webapp_dashboard`)
      const dashboardCount = response.data
      dispatch({
        type: 'GET_DASHBOARD_COUNT_SUCCESS',
        payload: dashboardCount
      })
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        dispatch({ type: 'GET_DASHBOARD_COUNT_FAILURE', error: error.message })
      }
    }
  }
}

export const setProvisionEmail = ({ params }: any) => {
  return async (dispatch: any) => {
    const access_token = localStorage.getItem('access_token')
    try {
      dispatch({ type: 'SET_PROVISION_EMAIL_REQUEST' }) // Set loading to true
      const response: any = await xcpemSec.post(`/rpc/webapp_dashboard_gw_to_acc_provision`, params, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })
      if (response.status === 200 || response.status === 204) {
        dispatch({ type: 'SET_PROVISION_EMAIL_SUCCESS', payload: response.message }) // Set loading to false
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return error?.response?.status
      } else {
        notifyError(`Provision Email: ${error.message}`)
        dispatch({ type: 'SET_PROVISION_EMAIL_FAILURE', payload: error.response.data.details }) // Set loading to false
      }
    }
  }
}
