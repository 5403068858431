import SecurityScoreCommon from '../common/security-score'
import SecurityScoreStaging from '../staging/security-score'
const ParentalControlTable: React.FC<any> = ({ title, score_suggestions, score }) => {
  return (
    <>
      {process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer' ? (
        <SecurityScoreStaging title={title} score_suggestions={score_suggestions} score={score} />
      ) : (
        <SecurityScoreCommon title={title} score_suggestions={score_suggestions} score={score} />
      )}
    </>
  )
}

export default ParentalControlTable
