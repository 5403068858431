import React from 'react'
import { getColorCode } from '../../../utils/helper'

const NotRegisteredHitron = ({ width, height, color }: any) => {
  const defaultColor = color ? color : getColorCode('lightblueToGray')
  const defaultWidth = width ? width : '80'
  const defaultHeight = height ? height : '100'

  return (
    <>
      <svg
        width={defaultWidth}
        height={defaultHeight}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15.5355 11.4645C14.991 10.9199 14.3428 10.5167 13.6355 10.2727C14.393 9.751 14.8906 8.87789 14.8906 7.89062C14.8906 6.29674 13.5939 5 12 5C10.4061 5 9.10938 6.29674 9.10938 7.89062C9.10938 8.87789 9.60701 9.751 10.3645 10.2727C9.65727 10.5167 9.00906 10.9199 8.46447 11.4645C7.5201 12.4088 7 13.6645 7 15H7.78125C7.78125 12.6738 9.67377 10.7812 12 10.7812C14.3262 10.7812 16.2188 12.6738 16.2188 15H17C17 13.6645 16.4799 12.4088 15.5355 11.4645ZM12 10C10.8369 10 9.89062 9.05375 9.89062 7.89062C9.89062 6.7275 10.8369 5.78125 12 5.78125C13.1631 5.78125 14.1094 6.7275 14.1094 7.89062C14.1094 9.05375 13.1631 10 12 10Z'
          fill={defaultColor}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.0477 16.5851L13.4324 17.9566L14.0008 17.3937L12.6161 16.0222L14.1074 14.545L13.539 13.9821L12.0477 15.4592L10.4497 13.8765L9.88135 14.4394L11.4793 16.0222L9.98796 17.4993L10.5564 18.0622L12.0477 16.5851Z'
          fill={defaultColor}
        />
      </svg>
    </>
  )
}

export default NotRegisteredHitron
