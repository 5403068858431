import TimeSuperVisionCommon from './common/time-supervisionCommon'
import TimeSuperVisionStaging from './staging/TimeSuperVisionStaging'

const ParentalControlTable: React.FC<any> = ({ email, isCard, filteringDevice, dataFilter, fromDates }) => {
  return (
    <>
      {process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer' ? (
        <TimeSuperVisionStaging
          email={email}
          isCard={isCard}
          filteringDevice={filteringDevice}
          dataFilter={dataFilter}
          fromDates={fromDates}
        />
      ) : (
        <TimeSuperVisionCommon
          email={email}
          isCard={isCard}
          filteringDevice={filteringDevice}
          dataFilter={dataFilter}
          fromDates={fromDates}
        />
      )}
    </>
  )
}

export default ParentalControlTable
